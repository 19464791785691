.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  max-height: 200px;
  pointer-events: none;
  margin: 50px;
  border-radius: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&display=swap");

.perl-poem {
  white-space: pre-line; /* Preserves line breaks and spaces */
  text-align: left; /* Aligns text to the left */
  color: "black";
  font-size: 24px;
  font-family: Dancing Script;
}

.rainbow-text {
  background-image: linear-gradient(
    to left,
    violet,
    indigo,
    blue,
    green,
    yellow,
    orange,
    red
  );
}

.App-header {
  background-image: url("./images/Egyptian-Hieroglyphs.jpeg");
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
